// proper initialization
var script = document.createElement('script');
script.src = 'https://code.jquery.com/jquery-3.6.0.min.js';
document.getElementsByTagName('head')[0].appendChild(script);
var deviceFingerprintInterval;
var deviceFingerprintCounter = 0;
var challengeCounter = 0;
var enableConsoleLogging = false;
var deviceFingerprinting_ProcessCompleted = false;
var deviceFingerprinting_ThreeDSServerTransID = "";
var deviceFingerprinting_ThreeDSMethodNotificationURL = "";
var deviceFingerprinting_EncodedThreeDSMethodData = "";
var deviceFingerprinting_ExceptionMessage = "";
var challenge_ProcessCompleted = false;
var challenge_ThreeDSServerTransID = "";
var challenge_TransStatus = "";
var challenge_ChallengeCompletionInd = "";
var challenge_ChallengeCancel = "";
var challenge_MessageVersion = "";
var challenge_MessageType = "";
var challenge_AcsTransID = "";
var challenge_EncodedCres = "";
var challenge_ExceptionMessage = "";
var tokenEx3DS = {
    perform3DS: async function (perform3DS_Request) {
        enableConsoleLogging = perform3DS_Request.data.enableConsoleLogging;
        var tokenEx3DS_Object_AdditionalValues =
        {
            /*
                Set the TokenEx3DS ActionID
                    1 = SupportedVersions
                    2 = Authentications
                    3 = ChallengeResults
            */
            tokenEx3DS_ActionID: 1,
            //get browser attributes
            browserJavaEnabled: navigator.javaEnabled(),
            browserJavascriptEnabled: true,
            browserLanguage: navigator.language,
            browserColorDepth: screen.colorDepth,
            browserScreenHeight: screen.height,
            browserScreenWidth: screen.width,
            browserTZ: new Date().getTimezoneOffset(),
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
            originalPaymentAmount: perform3DS_Request.data.amount
        };
        //combine the objects
        var tokenEx3DS_Object_Complete = Object.assign(perform3DS_Request.data, tokenEx3DS_Object_AdditionalValues);
        //stringify
        var supportedVersionsRequest = JSON.stringify(tokenEx3DS_Object_Complete);
        var performActionsEndpoint = tokenEx3DS_Object_Complete.tokenEx3DS_ActionsEndpoint;
        var challengeTimeoutTime = tokenEx3DS_Object_Complete.challengeTimeoutTime;
        if (enableConsoleLogging) { console.log("Determining if the card supports 3DS."); }
        var supportedVersionsResponse = await callPerformActions(performActionsEndpoint, supportedVersionsRequest);
        if (supportedVersionsResponse.Success) {
            if (supportedVersionsResponse.PerformDeviceFingerprinting) {
                var threeDSMethodCompleteInd = await performDeviceFingerprinting(supportedVersionsResponse);
                await performAuthentications(tokenEx3DS_Object_Complete, supportedVersionsResponse, threeDSMethodCompleteInd, performActionsEndpoint, challengeTimeoutTime, perform3DS_Request);
                return;
            }
            /*
                If SupportedVersions was successful but device fingerprinting was not supported, we would have called Authentications.
                This would be the scenario in which the Authentications call returned a challenge response.
            */
            if (supportedVersionsResponse.PerformChallenge) {
                await performChallenge(tokenEx3DS_Object_Complete, supportedVersionsResponse, performActionsEndpoint, challengeTimeoutTime, perform3DS_Request);
                return;
            }
            /*
                ThreeDSComplete
                    If SupportedVersions was successful but device fingerprinting was not supported, we would have called Authentications.
                    This would be the scenario in which the Authentications call returned a frictionless response (and we potentially already
                    perfomed a successful AuthOnly call).
                Bypass3DS
                    If the market was configured to attempt 3DS but does not require it (ShouldAttemptTokenEx3DS = true and Successful3dsIsRequired = false),
                    if something failed along the way or they were challenged, we would bypass the 3DS process and go straight to Authorizing the card.
            */
            if (supportedVersionsResponse.ThreeDSComplete || supportedVersionsResponse.Bypass3DS) {
                threeDSCompleteOrBypass3DSResults(supportedVersionsResponse, perform3DS_Request);
                return;
            }
            perform3DS_Request.error("3DS Error: Unexpected 3DS action - SupportedVersions success but action not PerformDeviceFingerprinting, "
                + "PerformChallenge, ThreeDSComplete, or Bypass3DS.");
            return;
        }
        //If the call to the Money-In SDK to perform TokenEx 3DS failed for whatever reason, pass this along to the caller via the error callback.
        else {
            perform3DS_Request.error(supportedVersionsResponse.Message);
            return;
        }
    }
};
async function callPerformActions(performActionsEndpoint, performActionsRequest) {
    return $.ajax({
        url: performActionsEndpoint,
        method: 'POST',
        dataType: 'json',
        contentType: "application/json; charset=utf-8",
        data: performActionsRequest,
      headers: {
        "__RequestVerificationToken": $('[name=__RequestVerificationToken]').val(),
        "X-Frame-Options": "SAMEORIGIN, ALLOW FROM https://rainretailapi.wsicloud.net, ALLOW-FROM https://rainretail.wsicloud.net",
        "Content-Security-Policy": "frame-ancestors 'self' https://rainretailapi.wsicloud.net https://rainretail.wsicloud.net;"
      }
    }).then(function (response) {
        return response;
    });
}
function delay(milliseconds) {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve();
        }, milliseconds);
    });
}
async function performDeviceFingerprinting(supportedVersionsResponse) {
    if (enableConsoleLogging) { console.log("Performing device fingerprinting."); }
    //Ensure the device fingerprinting completed flag is false before attempting device fingerprinting.
    deviceFingerprinting_ProcessCompleted = false;
    deviceFingerprinting_ThreeDSServerTransID = "";
    deviceFingerprinting_ThreeDSMethodNotificationURL = "";
    deviceFingerprinting_EncodedThreeDSMethodData = "";
    deviceFingerprinting_ExceptionMessage = "";
    deviceFingerprintCounter = 0;
    $("#ExigoTokenEx3DS_DeviceFingerprinting").remove();
    $("#DeviceFingerprintingPost_ExigoTokenEx3DS").remove();
    //Create the hidden device fingerprinting iframe and form, then post the form to the iframe.
    addDeviceFingerprintingIframeAndForm_ThenPost(supportedVersionsResponse);
    //Await a device fingerprinting response. Will wait 10 seconds (per 3DS specs) before proceeding.
    var deviceFingerprinted = await checkForDeviceFingerPrintResponse(supportedVersionsResponse.ThreeDSServerTransactionID);
    var threeDSMethodCompleteInd = "N";
    if (deviceFingerprinted == true) {
        threeDSMethodCompleteInd = "Y";
    }
    if (enableConsoleLogging) { console.log("Device Fingerprinting process complete. threeDSMethodCompleteInd: " + threeDSMethodCompleteInd); }
    return threeDSMethodCompleteInd;
}
function addDeviceFingerprintingIframeAndForm_ThenPost(supportedVersionsResponse) {
    //Create hidden iframe for device fingerprinting purposes.
    var deviceFingerprinting_Iframe = document.createElement("iframe");
    deviceFingerprinting_Iframe.id = "ExigoTokenEx3DS_DeviceFingerprinting";
    deviceFingerprinting_Iframe.name = "ExigoTokenEx3DS_DeviceFingerprinting";
    deviceFingerprinting_Iframe.style.cssText = "display:none;";
    //Append the hidden device fingerprinting iframe.
    document.body.appendChild(deviceFingerprinting_Iframe);
    //Create a hidden form that targets the hidden device fingerprinting iframe.
    var deviceFingerprinting_Form = document.createElement("form");
    deviceFingerprinting_Form.id = "DeviceFingerprintingPost_ExigoTokenEx3DS";
    deviceFingerprinting_Form.method = "POST";
    deviceFingerprinting_Form.action = supportedVersionsResponse.ThreeDSMethodURL;
    deviceFingerprinting_Form.style.cssText = "display:none;"
    deviceFingerprinting_Form.target = "ExigoTokenEx3DS_DeviceFingerprinting";
    deviceFingerprinting_Form.enctype = "application/x-www-form-urlencoded";
    deviceFingerprinting_Form.appendChild(createInput("threeDSMethodData", supportedVersionsResponse.ThreeDSMethodData));
    //Append the hidden form.
    document.body.appendChild(deviceFingerprinting_Form);
    //Submit the form.
    deviceFingerprinting_Form.submit();
}
function createInput(name, value) {
    var result = document.createElement("input");
    result.name = name;
    result.value = value;
    result.cssText = "display:none;";
    return result;
}
async function checkForDeviceFingerPrintResponse(threeDSTransactionID) {
    while (deviceFingerprintCounter <= 50) {
        if (enableConsoleLogging) { console.log("Awaiting device fingerprint response - " + deviceFingerprintCounter); }
        if (deviceFingerprinting_ProcessCompleted == true) {
            if (deviceFingerprinting_ThreeDSServerTransID == threeDSTransactionID) {
                return true;
            }
            else {
                return false;
            }
        }
        await delay(200);
        deviceFingerprintCounter++
    }
    return false;
}
async function performAuthentications(tokenEx3DS_Object_Complete, supportedVersionsResponse, threeDSMethodCompleteInd, performActionsEndpoint, challengeTimeoutTime, perform3DS_Request) {
    //Update info before sending back to the Actions endpoint.
    tokenEx3DS_Object_Complete.threeDSMethodCompleteInd = threeDSMethodCompleteInd;
    tokenEx3DS_Object_Complete.tokenEx3DS_ActionID = 2;
    tokenEx3DS_Object_Complete.originalGatewayID = supportedVersionsResponse.OriginalGatewayID;
    tokenEx3DS_Object_Complete.originalMerchantProcessTy = supportedVersionsResponse.OriginalMerchantProcessTy;
    tokenEx3DS_Object_Complete.threeDSServerTransactionID = supportedVersionsResponse.ThreeDSServerTransactionID;
    tokenEx3DS_Object_Complete.amount = supportedVersionsResponse.Amount;
    tokenEx3DS_Object_Complete.tokenEx3DS_Flow_TransactionID = supportedVersionsResponse.TokenEx3DS_Flow_TransactionID;
    tokenEx3DS_Object_Complete.threeDS_Version = supportedVersionsResponse.ThreeDS_Version;
    tokenEx3DS_Object_Complete.directoryServerID = supportedVersionsResponse.DirectoryServerID;
    if (threeDSMethodCompleteInd == "Y") {
        tokenEx3DS_Object_Complete.encodedThreeDSMethodData = deviceFingerprinting_EncodedThreeDSMethodData;
    }
    var authenticationsRequest = JSON.stringify(tokenEx3DS_Object_Complete);
    if (enableConsoleLogging) { console.log("Checking to see if a 3DS challenge is necessary for this transaction."); }
    //call Authentications
    var authenticationsResponse = await callPerformActions(performActionsEndpoint, authenticationsRequest);
    if (authenticationsResponse.Success) {
        if (authenticationsResponse.PerformChallenge) {
            await performChallenge(tokenEx3DS_Object_Complete, authenticationsResponse, performActionsEndpoint, challengeTimeoutTime, perform3DS_Request);
            return;
        }
        if (authenticationsResponse.ThreeDSComplete || authenticationsResponse.Bypass3DS) {
            if (enableConsoleLogging) { console.log("No Challenge necessary. 3DS process complete (or bypassed)."); }
            threeDSCompleteOrBypass3DSResults(authenticationsResponse, perform3DS_Request);
            return;
        }
        //Unexpected 3DS action for this point in the process, return a failure.
        perform3DS_Request.error("Unexpected 3DS action - Authentications success but action not PerformChallenge, ThreeDSComplete, or Bypass3DS.");
        return;
    }
    else {
        perform3DS_Request.error(authenticationsResponse.Message);
        return;
    }
}
async function performChallenge(tokenEx3DS_Object_Complete, moneyInSDK_Response, performActionsEndpoint, challengeTimeoutTime, perform3DS_Request) {
    if (enableConsoleLogging) { console.log("Performing 3DS challenge."); }
    challenge_ProcessCompleted = false;
    challenge_ThreeDSServerTransID = "";
    challenge_TransStatus = "";
    challenge_ChallengeCompletionInd = "";
    challenge_ChallengeCancel = "";
    challenge_MessageVersion = "";
    challenge_MessageType = "";
    challenge_AcsTransID = "";
    challenge_EncodedCres = "";
    challengeCounter = 0;
    $("#ExigoTokenEx3DS_Challenge").remove();
    $("#ChallengePost_ExigoTokenEx3DS").remove();
    
    //Create the challenge iframe and then post to it via a hidden form.
    addChallengeIframe_ThenPost(moneyInSDK_Response);
    //Await response from the webhook indicating that the customer is done with the challenge.
    var challengeResponse_JSON = await checkForChallengeResponse(challengeTimeoutTime);
    var challengeResponse = JSON.parse(challengeResponse_JSON);
    if (enableConsoleLogging) { console.log("The 3DS challenge process has completed."); }
    $("#TokenEx3DS_Challenge_ParentElement").hide();
    //These shouldn't ever happen, but check for these scenarios just in case.
    if (
        challengeResponse.errorMessage != "" ||
        (challengeResponse.threeDSServerTransactionID != "" && challengeResponse.threeDSServerTransactionID != moneyInSDK_Response.ThreeDSServerTransactionID) ||
        (challengeResponse.errorMessage == "" && challengeResponse.transStatus == "") ||
        (challengeResponse.transStatus != "" && (challengeResponse.transStatus.trim().toUpperCase() != "Y" && challengeResponse.transStatus.trim().toUpperCase() != "N"))
    ) {
        perform3DS_Request.error("Error with the challenge response data.");
        return;
    }
    //call ChallengeResults
    tokenEx3DS_Object_Complete.tokenEx3DS_ActionID = 3;
    tokenEx3DS_Object_Complete.threeDSServerTransactionID = challengeResponse.threeDSServerTransactionID;
    tokenEx3DS_Object_Complete.transStatus = challengeResponse.transStatus;
    tokenEx3DS_Object_Complete.aResTransStatus = moneyInSDK_Response.AResTransStatus;
    tokenEx3DS_Object_Complete.encodedCRes = challengeResponse.encodedCres;
    tokenEx3DS_Object_Complete.tokenEx3DS_Flow_TransactionID = moneyInSDK_Response.TokenEx3DS_Flow_TransactionID;
    tokenEx3DS_Object_Complete.originalGatewayID = moneyInSDK_Response.OriginalGatewayID;
    tokenEx3DS_Object_Complete.originalMerchantProcessTy = moneyInSDK_Response.OriginalMerchantProcessTy;
    tokenEx3DS_Object_Complete.threeDS_Version = moneyInSDK_Response.ThreeDS_Version;
    tokenEx3DS_Object_Complete.directoryServerID = moneyInSDK_Response.directoryServerID;
    tokenEx3DS_Object_Complete.amount = moneyInSDK_Response.Amount;
    var challengeResultsRequest = JSON.stringify(tokenEx3DS_Object_Complete);
    if (enableConsoleLogging) { console.log("Getting the detailed results of the Challenge."); }
    var challengeResultsResponse = await callPerformActions(performActionsEndpoint, challengeResultsRequest);
    if (challengeResultsResponse.Success == true) {
        if (challengeResultsResponse.ThreeDSComplete || challengeResultsResponse.Bypass3DS) {
            threeDSCompleteOrBypass3DSResults(challengeResultsResponse, perform3DS_Request);
            return;
        }
        //Unexpected 3DS action for this point in the process, return a failure.
        perform3DS_Request.error("Unexpected 3DS action - ChallengeResults success but action not ThreeDSComplete or Bypass3DS.");
        return;
    }
    else {
        perform3DS_Request.error(challengeResultsResponse.Message);
        return;
    }
}
function addChallengeIframe_ThenPost(authenticationsResponse) {
    //Create the challenge iframe.
    var challengeIframe = document.createElement("iframe");
    challengeIframe.id = "ExigoTokenEx3DS_Challenge";
    challengeIframe.name = "ExigoTokenEx3DS_Challenge";
    //Determine the height and width.
    if (authenticationsResponse.ChallengeWindowSize == 1) {
        challengeIframe.width = 250;
        challengeIframe.height = 400;
    }
    else if (authenticationsResponse.ChallengeWindowSize == 2) {
        challengeIframe.width = 390;
        challengeIframe.height = 400;
    }
    else if (authenticationsResponse.ChallengeWindowSize == 3) {
        challengeIframe.width = 500;
        challengeIframe.height = 600;
    }
    else {
        challengeIframe.width = 600;
        challengeIframe.height = 400;
    }
    //add styling here.
    challengeIframe.style.cssText = "background-color: white;";
    $("#TokenEx3DS_Challenge_ChildElement").width(challengeIframe.width);
    $("#TokenEx3DS_Challenge_ChildElement").height(challengeIframe.height);
    //Append the challenge iframe.
    document.getElementById("TokenEx3DS_Challenge_ChildElement").appendChild(challengeIframe);
    //Create a hidden form that targets the challenge iframe.
    var challengeForm = document.createElement("form");
    challengeForm.id = "ChallengePost_ExigoTokenEx3DS";
    challengeForm.method = "POST";
    challengeForm.action = authenticationsResponse.AcsURL;
    challengeForm.style.cssText = "display:none;";
    challengeForm.target = "ExigoTokenEx3DS_Challenge";
    challengeForm.enctype = "application/x-www-form-urlencoded";
    challengeForm.appendChild(createInput("creq", authenticationsResponse.EncodedCReq));
    //Append the hidden form.
    document.body.appendChild(challengeForm);
    $("#TokenEx3DS_Challenge_ParentElement").show();
    //Submit the form.
    challengeForm.submit();
}
async function checkForChallengeResponse(challengeTimeoutTime) {
    challengeCounter = 0;
    var frequency = 200;
    var challengeTimer = challengeTimeoutTime / frequency;
    var challengeResponse_Object =
    {
        transStatus: "",
        threeDSServerTransactionID: "",
        challengeCancel: "",
        encodedCres: "",
        errorMessage: "",
        timeout: false
    };
    while (challengeCounter <= challengeTimer) {
        if (challengeCounter % 100 == 0) {
            if (enableConsoleLogging) { console.log("Waiting for the challenge to be completed - " + (challengeCounter / 100)); }
        }
        //We have recieved a webhook response of some kind, we can stop checking now.
        if (challenge_ProcessCompleted == true) {
            challengeResponse_Object.threeDSServerTransactionID = challenge_ThreeDSServerTransID;
            challengeResponse_Object.transStatus = challenge_TransStatus;
            challengeResponse_Object.challengeCancel = challenge_ChallengeCancel;
            challengeResponse_Object.encodedCres = challenge_EncodedCres;
            challengeResponse_Object.errorMessage = challenge_ExceptionMessage;
            return JSON.stringify(challengeResponse_Object);
        }
        await delay(frequency);
        challengeCounter++
    }
    //If we reach this point, we know that we have gone past our timeout for waiting for a challenge response. Indicate that we timed out and return object.
    challengeResponse_Object.timeout = true;
    return JSON.stringify(challengeResponse_Object);
}
function threeDSCompleteOrBypass3DSResults(moneyInSDK_Response, perform3DS_Request) {
    var threeDS_Response =
    {
        tokenEx3DS_Identifier: moneyInSDK_Response.TokenEx3DS_Identifier,
        tokenEx3DS_Information: moneyInSDK_Response.TokenEx3DS_Information
    };
    perform3DS_Request.success(threeDS_Response);
}
window.addEventListener("message", (event) => {
    //This is used for both the Device Fingerprinting results and the Challenge results.
    if (event.data.DeviceFingerprintingProcessCompleted_ExigoTokenEx3DS === undefined && event.data.ChallengeProcessCompleted_ExigoTokenEx3DS === undefined) {
        return;
    }
    //Device Fingerprinting logic
    if (event.data.DeviceFingerprintingProcessCompleted_ExigoTokenEx3DS !== undefined) {
        //This should never be false, but just in case.
        if (event.data.DeviceFingerprintingProcessCompleted_ExigoTokenEx3DS == false) {
            return;
        }
        //If there was an exception, we wouldn't have an origin to compare to. Set the fingerprinting process as completed and continue.
        if (event.data.ExceptionMessage != null) {
            if (enableConsoleLogging) { console.log("Error in Device Fingerprinting webhook. Error Message: " + event.data.ExceptionMessage) }
            deviceFingerprinting_ProcessCompleted = true;
            deviceFingerprinting_ExceptionMessage = event.data.ExceptionMessage;
            return;
        }
        //If the origin does not match the set device fingerprinting webhook origin, return.
        //REMOVED FOR TESTING, IF THIS IS STILL UNCOMMENTED, COMMENT IT BACK IN
        //if (event.origin != (new URL(event.data.WebhookOrigin).origin)) {
        //    return;
        //}
        deviceFingerprinting_ProcessCompleted = true;
        deviceFingerprinting_ThreeDSServerTransID = event.data.ThreeDSServerTransID;
        deviceFingerprinting_ThreeDSMethodNotificationURL = event.data.ThreeDSMethodNotificationURL;
        deviceFingerprinting_EncodedThreeDSMethodData = event.data.EncodedThreeDSMethodData;
        if (enableConsoleLogging) { console.log("Device Fingerprinting webhook response: " + JSON.stringify(event.data)) }
        return;
    }
    //3DS Challenge logic
    if (event.data.ChallengeProcessCompleted_ExigoTokenEx3DS !== undefined) {
        //This should never be false, but just in case.
        if (event.data.ChallengeProcessCompleted_ExigoTokenEx3DS == false) {
            return;
        }
        //If there was an exception, we wouldn't have an origin to compare to. Set the 3DS challnge process as completed and continue.
        if (event.data.ExceptionMessage != null) {
            if (enableConsoleLogging) { console.log("Error in 3DS Challenge webhook. Error Message: " + event.data.ExceptionMessage) }
            challenge_ProcessCompleted = true;
            challenge_ExceptionMessage = event.data.ExceptionMessage;
            return;
        }
        //If the origin does not match the set 3DS challenge webhook origin, return.
        //REMOVED FOR TESTING, IF THIS IS STILL UNCOMMENTED, COMMENT IT BACK IN
        //if (event.origin != (new URL(event.data.WebhookOrigin).origin)) {
        //    return;
        //}
        challenge_ProcessCompleted = true;
        challenge_ThreeDSServerTransID = event.data.ThreeDSServerTransID;
        challenge_TransStatus = event.data.TransStatus;
        challenge_ChallengeCompletionInd = event.data.ChallengeCompletionInd;
        challenge_ChallengeCancel = event.data.ChallengeCancel;
        challenge_MessageVersion = event.data.MessageVersion;
        challenge_MessageType = event.data.MessageType;
        challenge_AcsTransID = event.data.AcsTransID;
        challenge_EncodedCres = event.data.EncodedCres;
        if (enableConsoleLogging) { console.log("3DS Challenge webhook response: " + JSON.stringify(event.data)) }
        return;
    }
}, false);
